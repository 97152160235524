<template>
  <div>
    <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 19 }">
      <a-form-item label="用户组名称">
        <a-input
          v-decorator="[
            'name',
            {
              rules: [{ required: true, message: '请输入用户组名称!' }],
              initialValue: record ? record.name : undefined,
            },
          ]"
        />
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
import {saveUserGroup} from '@/api/auth'
export default {
  name: "org-group-info",
  components: {},
  props: {
    record: {
      type: Object,
    },
    authOrgId: {
      type: Number,
      require: true,
    },
  },
  data() {
    return {
      form: this.$form.createForm(this),
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {},
    onOk() {
      return new Promise((resolve) => {
        this.form.validateFields((err, values) => {
          if(!err) {
            let formData = {
              ...values,
              authOrgId:this.authOrgId
            }
            if(this.record) {
              formData.id = this.record.id
            }
            saveUserGroup(formData).then(() => {
              resolve(true)
            })
          }
        })
      })
    }
  },
};
</script>

<style scoped></style>
