<template>
  <div>
    <div>
      <a @click="goBack"><a-icon type="left" />返回机构管理列表</a>
    </div>
    <div class="title">{{ orgName }}</div>
    <a-card :bordered="false">
      <a-button
        type="primary"
        icon="plus"
        @click="handleAdd"
        v-if="hasAction('edit-user-org-group')"
        >新建</a-button
      >
      <a-table
        ref="table"
        size="default"
        :row-key="(record) => record.id"
        :columns="columns"
        :data-source="groupList"
        :pagination="{ pageSize: 10 }"
      >
        <div slot="action" slot-scope="record">
          <template>
            <a
              @click="handleEdit(record)"
              v-if="hasAction('edit-user-org-group')"
              >用户组信息</a
            >
            <!-- <a-divider
              type="vertical"
              v-if="hasAction('edit-role') && hasAction('delete-role')"
            /> -->
            <!-- <a
              @click="handleEditGroupDataPermissions(record)"
              v-if="hasAction('edit-role')"
              >用户组数据权限</a
            > -->
            <a-divider
              type="vertical"
              v-if="hasAction('edit-user-org-group')"
            />
            <a @click="handleEditProcess(record)">用户组流程配置</a>
            <a-divider
              type="vertical"
              v-if="hasAction('edit-user-org-group')"
            />
            <a-popconfirm
              title="确认移除吗？"
              ok-text="是"
              cancel-text="否"
              @confirm="handleDelete(record)"
              v-if="hasAction('edit-user-org-group')"
            >
              <a>删除</a>
            </a-popconfirm>
          </template>
        </div>
      </a-table>
    </a-card>
  </div>
</template>

<script>
import { getUserGroupDetailListByOrgId, deleteUserGroup } from "@/api/auth";
import RuleEdit from "../components/RuleEdit";
import OrgGroupDataPermissions from "./components/org-group-data-permissions.vue";
import OrgGroupInfo from "./components/org-group-info.vue";
import OrgGroupProcess from "./components/org-group-process.vue";

let columns = [
  {
    title: "#",
    dataIndex: "id",
  },
  {
    title: "用户组名称",
    dataIndex: "name",
    width: 200,
  },
  {
    title: "成员名单",
    dataIndex: "users",
    customRender: (text) => {
      return text.join("、");
    },
  },
];

export default {
  data() {
    return {
      columns: columns,
      groupList: [],
      selectedRole: {},
      orgName: this.$route.query.orgName,
      orgId: parseInt(this.$route.query.orgId),
    };
  },
  created() {
    if (
      (this.hasAction("edit-role") || this.hasAction("delete-role")) &&
      this.columns.length < 4
    ) {
      this.columns.push({
        title: "操作",
        scopedSlots: {
          customRender: "action",
        },
        width: 400,
      });
    }
    this.loadData();
  },
  methods: {
    loadData() {
      getUserGroupDetailListByOrgId({ orgId: this.orgId }).then((res) => {
        if (res.code === 0) {
          this.groupList = res.data;
        }
      });
    },
    handleAdd() {
      this.$dialog(
        OrgGroupInfo,
        {
          authOrgId: this.orgId,
          on: {
            ok: () => {
              this.loadData();
            },
          },
        },
        {
          title: "新增用户组信息",
          width: 500,
          centered: true,
          maskClosable: false,
        }
      );
    },
    handleEdit(record) {
      this.$dialog(
        OrgGroupInfo,
        {
          record,
          authOrgId: this.orgId,
          on: {
            ok: () => {
              this.loadData();
            },
          },
        },
        {
          title: "编辑用户组信息",
          width: 500,
          centered: true,
          maskClosable: false,
        }
      );
    },
    handleDelete(record) {
      deleteUserGroup(record.id).then(() => {
        this.loadData();
      });
    },
    handleEditGroupDataPermissions(record) {
      this.$dialog(
        OrgGroupDataPermissions,
        {
          orgId: this.orgId,
          mainGroupId: record.id,
          on: {
            ok: () => {
              this.loadData();
            },
          },
        },
        {
          title: "用户组数据权限配置",
          width: 700,
          centered: true,
          maskClosable: false,
        }
      );
    },
    handleEditProcess(record) {
      let option = {
            title: "用户组流程配置",
            width: 500,
            centered: true,
            maskClosable: false,
          }
      if (!this.hasAction("edit-user-org-group")) {
        option.footer = () => null
      }
      this.$dialog(
          OrgGroupProcess,
          {
            record,
            on: {
              ok: () => {
                this.loadData();
              },
            },
          },
          option
        );
    },
    handle(record) {
      this.$dialog(
        RuleEdit,
        {
          record,
          on: {
            ok: () => {
              this.loadData();
            },
          },
        },
        {
          title: "权限管理",
          okText: "保存",
          width: 1000,
          centered: true,
          maskClosable: false,
        }
      );
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="less" scoped>
.title {
  margin-top: 24px;
  margin-left: 24px;
  padding: 5px 12px;
  background-color: #e6f7ff;
  width: fit-content;
  border-radius: 5px;
}
</style>
