<template>
  <div>
    <a-form :form="form">
      <a-form-item>
        <template #label>
          <span
            >设置与该用户组数据共通的用户组<span style="color: #f82c5b"
              >（多选）</span
            ></span
          >
        </template>
        <a-select
          mode="multiple"
          :value="sharedGroup"
          style="width: 100%"
          placeholder="请选择用户组"
          @change="handleChange"
        >
          <a-select-option v-for="item in groupList" :key="item.id" :value="item.id">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
import { getUserGroupListByOrgId,getSharedUserGroup,savaUserGroupShared } from "@/api/auth";
export default {
  name: "org-group-data-permissions",
  components: {},
  props: {
    orgId:{
      type:Number,
      require:true
    },
    mainGroupId:{
      type:Number,
      require:true
    }
  },
  data() {
    return {
      form: this.$form.createForm(this),
      groupList:[],
      sharedGroup:[]
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      getUserGroupListByOrgId({orgId:this.orgId}).then(res => {
        this.groupList = res.data.filter(item => item.id !== this.mainGroupId)
      })
      getSharedUserGroup({mainGroupId:this.mainGroupId}).then(res => {
        this.sharedGroup = res.data.map(item => item.id)
      })
    },
    handleChange(value) {
      this.sharedGroup = value
    },
    onOk() {
      return new Promise((resolve) => {
        savaUserGroupShared({
          mainGroupId:this.mainGroupId,
          shareGroupIds:this.sharedGroup
        }).then(() => {
          resolve(true)
        })
      })
    }
  },
};
</script>

<style scoped></style>
