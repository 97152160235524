<template>
  <div>
    <div v-for="process in processes" :key="process.title">
      <div>
        <strong>{{ process.title }}</strong>
      </div>
      <div style="margin: 6px 0 12px 0">
        <a-checkbox
          v-for="option in process.options"
          :key="option.value"
          @change="onChange(option)"
          :checked="option.checked"
          >{{ option.title }}</a-checkbox
        >
      </div>
    </div>
  </div>
</template>

<script>
import { saveUserGroupPermissionConfig } from "@/api/auth";
export default {
  name: "org-group-process",
  components: {},
  props: {
    record: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {
      processes: [
        {
          title: "待跟进-已立项",
          options: [
            {
              title: "存在联系记录",
              value: "contactRecord",
              checked: false,
            },
            {
              title: "完成立项会",
              value: "projectMeeting",
              checked: false,
            },
          ],
        },
        {
          title: "已立项-投委会",
          options: [
            { title: "完成尽调", value: "finishSurvey", checked: false },
            {
              title: "完成预投会",
              value: "finishPreMeeting",
              checked: false,
            },
            { title: "关联基金", value: "relateFund", checked: false },
          ],
        },
        {
          title: "投委会-投后管理",
          options: [
            {
              title: "完成所有投委会记录",
              value: "finishMeetingVote",
              checked: false,
            },
          ],
        },
      ],
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.processes.forEach((item) => {
        item.options.forEach((i) => {
          i.checked = this.record.permissionConfig[i.value];
        });
      });
    },
    onChange(option) {
      option.checked = !option.checked;
    },
    onOk() {
      return new Promise((resolve) => {
        let permissionConfig = {
          groupId:this.record.id
        };
        this.processes.forEach((item) => {
          item.options.forEach((i) => {
            permissionConfig[i.value] = i.checked;
          });
        });
        saveUserGroupPermissionConfig(permissionConfig).then(() => {
          resolve(true)
        })
      });
    },
  },
};
</script>

<style scoped></style>
